<template>
    <section>
        <div class="content-wrapper">
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-9">
                    <div class="text-background">
                        <img src="@/assets/images/import/h1-boutique.png" alt="">
                        <div class="top-left">
                            <h1>
                                <!-- text modifiable -->
                                Boutique
                            </h1>
                            <h3 class="mt-2 ml-5 gold-yellow">
                                <!-- text modifiable -->
                                 Sur commande ou en magasin
                            </h3>

                        </div>
                    </div>
                </div>
            </div>
            <!-- Recherche et filtre + liste des produits -->
            <div class="row mt-5">
                <div class="col-md-3">
                    <b-form-group>
                        <b-input-group>
                            <b-input-group-text slot="prepend" class="text-white">
                            <span><i class="fa fa-search" style="color:grey"></i></span>
                            </b-input-group-text>
                            <b-form-input placeholder="Rechercher"></b-form-input>
                        </b-input-group>
                    </b-form-group>
                    <p class="gold-yellow">Nohem</p>
                    <p class="gold-yellow">beauty garden</p>
                    <p class="gold-yellow">1914 paris</p>
                </div>
                <div class="col-md-9">
                    <div class="d-flex justify-content-between">
                        <h4>Tous mes produits</h4>

                        <!-- Categorie -->
                        <h4>tisanes</h4>
                        <h4>crèmes</h4>
                        <h4>maquillage</h4>
                        <h4>nouveautés</h4>
                        <!-- end categorie -->

                    </div>
                    <div class="mt-2 mb-5">
                        <p class="light">
                            <string-text
                            v-for="(description, descriptionId) in text"
                            :key="descriptionId"
                            :items="description"
                            :edit="false"
                            />
                        <string-text/>
                        </p> 
                    </div>

                    
                    <div v-for="block in products.blocks" :key="block.id">
                        <product-card-meb
                        :card="block.data.card"
                        />
                    </div>

                </div>
            </div>
            <!-- Promotions -->
        </div> <!-- /wrapper -->
    </section>
</template>

<script>
import stringText from './string-text.vue'
import products from '../../pages/json/products-meb.json'
import productCardMeb from './product-card-meb.vue'
export default {
    name:'boutique-meb',
    components: {
        stringText,
        productCardMeb
    },
    data () {
        return {
            products : products
        }
    },
    props: {
        text : Array
    },
    methods:{},
    mounted:{},
    
}
</script>

<style lang="scss" scoped>
// font
@import url('https://use.typekit.net/ept6vwi.css');
p {
  font-family: swear-display, serif;
font-style: normal;
font-weight: 300
}
.light {
  font-family: swear-display, serif;
  font-style: normal;
  font-weight: 200
}
h1 {
  // font-family: 'Alex Brush', cursive;
  // font-size: 55px;
  // font-style: normal;
  // font-weight: 300;
font-family: lush, sans-serif;
font-size: 90px;
font-weight: 400;
font-style: normal;

}
h2 {
  // font-family: 'Alex Brush', cursive;
  // font-size: 45px;
  // font-style: normal;
  // font-weight: 300;
  font-family: 'swear-display', serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
}
h3 {
  font-family: swear-display, serif;
  font-style:italic;
  font-size: 30px;
  font-weight: 300
}
h4 {
  font-family: swear-display, serif;
  font-style:normal;
  font-size: 20px;
  font-weight: 300
}

// media query
@media screen and (max-width: 992px) {
  .computer {
      display: none !important
  }
}
@media screen and (min-width: 992px) {
  .mobile {
      display: none !important
  }
}

//style color
.no-color {
  color: black
}
.no-color:hover {
  text-decoration: none !important;
  border: none;
}
// header
.gold-yellow{
    color: #e0d075
  }
hr.gold-yellow {
  border: #e0d075 1px solid
}
//body
.container-scroller .page-body-wrapper, .content-wrapper {
  background: #fff;
}
.container{
    max-width: 1800px;
}
.background-green {
  background: #EAEEE8;
  border-top: #000 solid 1px;
  border-bottom: #000 solid 1px;
}

//shop, actu, sidebar 
@media screen and (max-width: 992px) {
  .sidebarCategories {
      display: none !important
  }
}
@media screen and (min-width: 992px) {
  .sidebarCategories2 {
      display: none !important
  }
}

.bg-gradient-success{
  /* background: linear-gradient(to right, #353535, #000); */
  background:linear-gradient(to right, #dfc23a, #ffb159)
}

.filter-category {
    max-height: 200px;
    overflow: auto;
}

.content-wrapper{
  max-width: 1280px;
}

.no-background {
  background: none;
}

//liste avec icones checks
.checked-icon {
  list-style:none
}
.checked-icon li:before {
  content:'✓'
}

//product zoomer
.scroller-at-bottom{
  height: auto !important;
  width: 100% !important;
  position: relative !important;
}
.responsive-image.preview-box{
    width: 100% !important
}
.thumb-list{
    width: 100% !important;
    height: auto !important;
}
#zoomer-bottom-pane-container{
  height: 500px !important;
  width: 563px;
  left: 400px;
}

// timePicker
.b-time-footer {
  display: none !important;
}
</style>

<style scoped>
.text-background img {
    max-width: 150%;
}
.text-background {
    position: relative;
}
.top-left {
    position: absolute;
    top: 30%;
    left: 12%;
}

/* Css global de mon echappeebelle */

</style>